import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll, faLightbulb, faUser } from "@fortawesome/free-solid-svg-icons";
import "./about-information.scss";

function AboutInformation() {

    return (
        <div className="about-information">
            <h2>About</h2>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
                Eu ultrices vitae auctor eu augue ut lectus arcu bibendum. Scelerisque fermentum dui faucibus in ornare quam. Amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Egestas maecenas pharetra convallis posuere morbi leo urna. Nisl rhoncus mattis rhoncus urna neque viverra. Magna fermentum iaculis eu non diam phasellus vestibulum. Nulla malesuada pellentesque elit eget gravida cum. Risus ultricies tristique nulla aliquet enim tortor at auctor urna. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Sem viverra aliquet eget sit amet. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Praesent semper feugiat nibh sed pulvinar proin. Leo vel fringilla est ullamcorper eget nulla facilisi. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus. Feugiat in ante metus dictum. Ut consequat semper viverra nam libero justo laoreet.
            </p>

            <div className="cards">
                <span className="card">
                    <FontAwesomeIcon icon={faScroll} />
                    <h4>Law is our Passion</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed sed risus pretium quam vulputate dignissim. Iaculis eu non diam phasellus vestibulum lorem. Lorem</p>
                </span>
                <span className="card">
                    <FontAwesomeIcon icon={faLightbulb} />
                    <h4>Unique and knowledgable solutions</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed sed risus pretium quam vulputate dignissim. Iaculis eu non diam phasellus vestibulum lorem. Lorem donec massa sapien faucibus et molestie. Aenean euismod elementum nisi quis. Maecenas volutpat blandit aliquam etiam era</p>
                </span>
                <span className="card">
                    <FontAwesomeIcon icon={faUser} />
                    <h4>Unwavering approach</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed sed risus pretium quam vulputate dignissim. Iaculis eu non diam phasellus vestibulum lorem. Lorem</p>
                </span>

            </div>
        </div>
    )
}

export default AboutInformation;