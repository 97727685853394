import React from "react";
import background from "../../img/granite.png"
import "./about-header.scss";

function AboutHeader() {

    return (
        <div className="about-details" style={{ backgroundImage: `url(${background})`}}>
            <h3>Lawyers who care and commit to you</h3>
            <p>using the best legal pracices to help all our clients with their cases</p>
        </div>
    )
}

export default AboutHeader;