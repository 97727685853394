import React from "react";
import image from '../../img/statue.png';
import "./details.scss";
import { useScreenSize } from "../../hooks";

function Details() {

    const screenSize = useScreenSize();

    return (
        <div className={screenSize.width >= 1100 ? "details-section" : "details-section-mobile"} >
            { screenSize.width >= 1100 && <img alt="Statue" src={`${image}`} />}
            <div className="text">
                <h3>What we offer</h3>
                <div className="paragraphs">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas tellus rutrum tellus pellentesque eu. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet non. Imperdiet proin fermentum leo vel orci porta non pulvinar neque. Commodo quis imperdiet massa tincidunt nunc pulvinar. Sit amet dictum sit amet. Lorem mollis aliquam ut porttitor. Purus ut faucibus pulvinar elementum integer enim. Morbi leo urna molestie at elementum eu facilisis sed. Sodales neque sodales ut etiam. Enim praesent elementum facilisis leo vel fringilla est ullamcorper eget. Facilisis volutpat est velit egestas dui. Aenean et tortor at risus viverra adipiscing at in tellus. Non tellus orci ac auctor augue mauris augue neque. Duis convallis convallis tellus id interdum velit laoreet id donec.
                    </p>
                    
                </div>
            </div>
        </div>
    )
}

export default Details