import React, { useState } from "react";
import {useNavigate} from "react-router-dom"
import { useScreenSize } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "@mui/material"
import logo from '../../img/logo.png';
import "./header.scss";



function Header() {

    const screenSize = useScreenSize();
    const navigate = useNavigate();

    const [openDrawer, setOpenDrawer] = useState<boolean>(false)

    const handleNavigate = (path: string) => {
        if (path === "home") {
            navigate("/")
        }
        if (path === "about") {
            navigate("/about")
        }
    }

    return (
        <>
            {
                screenSize.width > 1100 ?
                <div className="header">
                    
                    <div className="name-logo" onClick={() => handleNavigate("home")}> 
                        <img alt="logo" src={`${logo}`}/>
                        <h3 className="ns">Strydom Attorneys</h3>
                    </div>
                    <div className="menu">
                        <span className="menu-item ns" onClick={() => handleNavigate("about")}>About</span>
                        <span className="menu-item ns">Specialities</span>
                        <span className="menu-item ns"><FontAwesomeIcon icon={faEnvelope} /><span className="text">Contact Us</span></span>
                    </div>
                </div>
                : 
                <div className="header-mobile">
                    <div className="name-logo">
                        <img alt="logo" src={`${logo}`}/>
                        <h3 className="ns">Strydom Attorneys</h3>
                    </div>
                    <span className="menu-button-section"><FontAwesomeIcon icon={faBars} className="menu-button" onClick={() => setOpenDrawer(true)}/></span>
                </div>
            }
             <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <div className="drawer">
                    <p>Hello</p>
                    <p>this</p>
                    <p>is</p>
                    <p>a</p>
                    <p>menu</p>
                </div>
            </Drawer>
        </>
    )
}

export default Header;
