import React from "react";
import contract1 from "../../img/contract1.jpg";
import contract2 from "../../img/contract2.png";
import criminal from "../../img/criminal.jpg";
import documents1 from "../../img/documents1.png";
import estate from "../../img/estate.jpg";
import graphs from "../../img/graphs.jpg";
import wedding from "../../img/wedding.png";
import workers from "../../img/workers.png";
import "./expertise-section.scss";
import { useScreenSize } from "../../hooks";

function ExpertiseSection() {

    const screenSize = useScreenSize();

    return (
        <div className={ screenSize.width >= 1100 ? "expertise ns" : "expertise-mobile ns"}>
            <div className="block">
                <div className="expertise-block" style={{ backgroundImage: `url(${contract1})` }}>
                    <p>Notary</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${contract2})` }}>
                    <p>Contracts</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${criminal})` }}>
                    <p>Criminal</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${documents1})` }}>
                    <p>Affidavit</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${estate})` }}>
                    <p>Estates</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${graphs})` }}>
                    <p>Debt Collection</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${wedding})` }}>
                    <p>Wedding Contracts</p>
                </div>
                <div className="expertise-block" style={{ backgroundImage: `url(${workers})` }}>
                    <p>Workers</p>
                </div>
            </div>
        </div>
    )
}

export default ExpertiseSection;

