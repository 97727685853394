import React from "react"
import "./footer.scss";

function Footer() {

    return (
        <div className="footer">
            <div className="menus">
            <div className="menu">
                <div className="sub-header">Quick Links</div>
                <div className="item">Home</div>
                <div className="item">About</div>
                <div className="item">Areas of service</div>
                <div className="item">Contact us</div>
            </div>

            <div className="menu">
                <div className="sub-header">Contact info</div>
                <div className="item">Contact number: 082 123 4567</div>
                <div className="item">Email: strydom@strydom.co.za</div>
            </div>
            </div>

            <div className="disclaimer">
                <div className="item">Privacy Policy</div>
                <div className="item">Terms & Conditions</div>
                <div className="item">Site Map</div>
                <div className="item">General: info@strydom.co.za</div>
            </div>
        </div>
    )
}

export default Footer;