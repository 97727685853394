import React from "react";
import "./main.scss";
import { 
    Header, 
    InfoSection, 
    ExpertiseSection, 
    Details, 
    Values,
    Footer
} from "../../components";

function Main() {

    return (
        <div className="main">
            <Header />
            <InfoSection />
            <ExpertiseSection />
            <Details />
            <Values />
            <Footer />
        </div>
    )
}

export default Main