import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll, faUserFriends, faSuitcase } from "@fortawesome/free-solid-svg-icons"
import background from '../../img/granite.png';
import "./values.scss";
import { useScreenSize } from "../../hooks";



function Values() {

    const screenSize = useScreenSize();

    return (
        <div className={ screenSize.width >= 1100 ? "values" : "values-mobile"} style={{ backgroundImage: `url(${background})`}}>
            <h1>Our Values</h1>
            <div className="content">
                <div className="cards">
                    <div className="card">
                        <FontAwesomeIcon icon={faScroll} />
                        <span className="label">Law</span>
                    </div>
                    <div className="card">
                        <FontAwesomeIcon icon={faSuitcase} />
                        <span className="label">Ethics</span>
                    </div>
                    <div className="card">
                        <FontAwesomeIcon icon={faUserFriends} />
                        <span className="label">Values</span>
                    </div>
                </div>
                <div className="text">
                    <p>
                        Hac habitasse platea dictumst quisque sagittis purus sit amet volutpat. Convallis posuere morbi leo urna molestie at elementum eu facilisis. Purus ut faucibus pulvinar elementum integer enim. Adipiscing at in tellus integer. Mi proin sed libero enim sed faucibus turpis in. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac. Donec adipiscing tristique risus nec feugiat. Sed elementum tempus egestas sed sed risus pretium quam. Sit amet commodo nulla facilisi nullam vehicula ipsum a arcu. Aliquam vestibulum morbi blandit cursus risus at ultrices mi tempus. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Ullamcorper a lacus vestibulum sed. Risus nec feugiat in fermentum posuere urna nec tincidunt. Nullam non nisi est sit. Tempor orci dapibus ultrices in iaculis nunc sed augue lacus. Consectetur libero id faucibus nisl.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Values