import React from "react";
import "./about.scss";
import { AboutHeader, Header, AboutInformation } from "../../components";

function About() {

    return (
        <div className="about">
            <Header />
            <AboutHeader />
            <AboutInformation />
        </div>
    )
}

export default About;