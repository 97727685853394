import React from "react";
import background from '../../img/granite.png';
import "./info-section.scss";
import { useScreenSize } from "../../hooks";

function InfoSection() {

    const screenSize = useScreenSize();

    return (
        <div className={ screenSize.width >= 1100 ? "info-section ns" : "info-section-mobile ns"} style={{ backgroundImage: `url(${background})`}}>
            <h3 className="hr-lines">Strydom Attorneys</h3>
            <h4>
                Uncompromising profesionality with innovative and reliable services for you
            </h4>
            <p>
                We offer a wide range of services to fit the needs of every client. 
            </p>

            <div className="sub-menu">
                <span className="sub-menu-button">
                    Get in touch
                </span>
                <span className="sub-menu-button">
                    Practice areas
                </span>
            </div>
        </div>
    )
}

export default InfoSection;