import React from 'react';
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { Main, About } from './pages';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="about" element={<About />} />
        {/* FALLBACK */}  
        {/* <Route path="*" element={<NoMatch />} /> */}
        
      </Routes>
    </div>
  );
}

export default App;
